@import 'helpers/styles/colors.module.scss';
@import 'helpers/styles/layouts.module.scss';
.container {
  table,
  th,
  td {
    border: 1px solid rgba(55, 53, 47, 0.09);
    border-collapse: collapse;
  }

  table {
    border-left: none;
    border-right: none;
    width: 100%;
  }

  th,
  td {
    font-weight: normal;
    padding: 0.25em 0.5em;
    line-height: 1.5;
    min-height: 1.5em;
    text-align: left;
  }

  th {
    font-weight: 700;
  }

  li {
    padding-bottom: 0.5rem;
  }
  li > ol:first-child,
  li > ul:first-child {
    margin-block-start: 0.6em;
  }
}
