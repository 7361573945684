@mixin typography-title {
  font-family: 'Lato', sans-serif;
  font-weight: 900;
}
@mixin typography-header {
  font-family: 'Lato', sans-serif;
  font-weight: 600;
}
@mixin typography-subheader {
  font-family: 'Inter', sans-serif;
  font-weight: 400;
}

@mixin typography-caption {
  font-family: 'Inter', sans-serif;
  font-weight: 400;
  text-transform: uppercase;
}
@mixin typography-thin {
  font-family: 'Inter', sans-serif;
  font-weight: 300;
}
@mixin typography-body {
  font-family: 'Inter', sans-serif;
  font-weight: 400;
}
@mixin typography-bold {
  font-family: 'Inter', sans-serif;
  font-weight: 500;
}
@mixin typography-bolder {
  font-family: 'Inter', sans-serif;
  font-weight: 600;
}
